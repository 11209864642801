import navLogo from '../photos/misc/fwcas logo.jpg';
import { navigationData } from "../constants/1. Global";
import NavContext from "../contexts/NavContext";
import {useContext, useEffect} from "react";

export const Navigation = () => {

    let nav: any, setNav: any;
    [nav, setNav] = useContext(NavContext);

    useEffect(() => {
        if (window.innerWidth < 750) {
            // @ts-ignore
            document.getElementById('nav-bar').style.minHeight = `${window.innerHeight}px`;
        }
    }, [])

    return (
        <nav
            id="nav-bar"
            style={{top: `${nav ? window.innerWidth < 750 ? "0" : "0" : window.innerWidth < 750 ? "0" : "-60px" }`, left: `${nav ? window.innerWidth < 750 ? "Calc(100vw - 7em" : "0" : window.innerWidth < 750 ? "Calc(100vw)" : "0" }`}}>
            <div id="fwcas-logo-container" className="nav-object">
                <img id="fwcas-logo" src={navLogo} alt={"fwcas logo"} />
            </div>
                {
                    navigationData.map( (e, i) => {
                        let yomama1 = e.a !== undefined
                        return (
                            <div className="nav-object" key={`NavItem:${i}`}>
                                <a href={e?.a} className={yomama1 ? "show" : "dont"}>
                                    <span className="nav-label">
                                        {e.title}
                                    </span>
                                </a>
                                <div className="nav-links-container">
                                    {
                                        e.subLabels.map( (a, p) => {
                                            return (
                                                <div className="link-container" key={`NavSubItem:${p}`}>
                                                    <span className="nav-link">
                                                        {
                                                            a
                                                        }
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
        </nav>
    )
}