import { Link } from "react-router-dom";
import {navigationData} from "../constants/1. Global";

export const Footer = () => {
    return (
        <>
            <div id="footer">
                <div id="footer-nav-link-container">
                    {
                        navigationData.map( (e: any, i: any) => {
                            let yomama = e?.a !== undefined
                            // console.log(yomama);
                            // console.log(e?.a);
                            return (
                                <div className="footer-nav-link-containers" key={`FooterItem:${i}`}>
                                    <Link to={e?.a} className={yomama ? "show" : ""}>
                                        <span className="footer-nav-link-titles">{toProperCase(e.title)}</span>
                                    </Link>
                                    <div className="footer-nav-sub-link-containers">
                                        {e.subLabels.map( (a: any, p: any) => {
                                            if (a === "FWCAS Events") {
                                                return (
                                                    <>
                                                        <Link to="/events" className="show" key={`FooterSubItem:${p}`}>
                                                            <span  className="footer-nav-sub-links highlight-link">{toProperCase(a)}</span>
                                                        </Link>
                                                    </>
                                                )
                                            } else if (a === "FORMS") {
                                                return (
                                                    <Link to="/forms" className="show" key={`FooterSubItem:${p}`}>
                                                        <span className="footer-nav-sub-links highlight-link">{toProperCase(a)}</span>
                                                    </Link>
                                                )
                                            } else if (a === "BOARD") {
                                                return (
                                                    <Link to="/board" className="show" key={`FooterSubItem:${p}`}>
                                                        <span className="footer-nav-sub-links highlight-link">{toProperCase(a)}</span>
                                                    </Link>
                                                )
                                            }
                                            return (
                                                <span key={`FooterSubItem:${p}`} className="footer-nav-sub-links">{toProperCase(a)}</span>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="footer-nav-link-containers">
                        <Link to="/admin" className="show">
                            <span className="footer-nav-link-titles">Admin</span>
                        </Link>
                    </div>
                </div>
                <span className="copyright">
                    © 2023
                </span>
                <span className="copyright">
                     Florida West Coast Avian Society, Inc.
                </span>
                <span className="copyright">
                    All rights reserved.
                </span>
            </div>
        </>
    )
}

const toProperCase = (text: string) => {
    return text.toLowerCase().split(" ").map( e => {
        switch (e) {
            case "fwcas":
                return e.toUpperCase();
            case "etc.":
            case "of":
            case "the":
                return e;
            default: break;
        }
        return e.split("").map( (a, i) => {
            return i === 0 ? a.toUpperCase() : a;
        }).join("");
    }).join(" ");
}
