import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import {deleteEvent} from '../api/events-api'
import AuthContext from '../contexts/AuthContext';

const Event = (props: any) => {
    
    // @ts-ignore
    const [auth, setAuth] = useContext(AuthContext);

    const getDate = () => {
        let time = new Date(props.data.timestamp.S);
        return `${time.getMonth() + 1}/${time.getDate()}/${time.getUTCFullYear()}`
    }

    const openEdit = () => {
        props.setEditing(true);
        props.setCurrentEvent(props.data);
        props.setNewPage(true);
    }

    const handleDelete = async () => {
        let confirmDelete = window.confirm("Are you sure you want to delete this event?");
        if (confirmDelete) {
            props.setLoading(true);
            const res = await deleteEvent(props.data);
            console.log(res);
            if (res) {
                props.refresh();
            } else {
                props.setLoading(false);
            }
        }
    }
    return (
        <div
            className="an-event-container">
            <Link to={`/event/${props.data.id.S}`}><span>{props.data.name.S}</span></Link>
            <div className="event-meta-info-container">
                <span>{getDate()}</span>
                <span>{props.data.location.S}</span>
            </div>
            {
                auth.authenticated ? (
                    <div className="cms-buttons-container">
                        <button onClick={(e) => openEdit()}>
                            EDIT
                        </button>
                        <button onClick={(e) => handleDelete()}>
                            DELETE
                        </button>
                    </div>
                ) : null
            }
        </div>
    )
}

export default Event;