import {functionalURL} from "../constants/1. Global";
import {getCookie, updateCookie} from "../utilities/3. Cookies";

export const analyzeLogin = (data: any) => {
    return fetch(functionalURL, {
        method: 'get',
        credentials: 'include',
        headers: {
            "authorization": `Basic: ${data}`,
            "action": 'analyze-login-info',
        }
    }).then(r => {
        return r.json();
    }).then(json => {
        // console.log(json);
        return json.body;
    }).catch(err => {
        // console.log(err);
        return false;
    })
}

export const checkToken = async () => {
    let token = getCookie('avian-auth');
    return await fetch(functionalURL, {
        method: 'get',
        credentials: 'include',
        headers: {
            "authorization": `Bearer ${token}`,
            "action": 'check-token',
        }
    }).then(r => {
        return r.json();
    }).then(json => {
        // // console.log(json);
        updateCookie(json.body["verificationResults"]['newToken']);
        return json.body;
    }).catch(err => {
        // console.log(err);
        return false;
    })
}