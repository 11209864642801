import { resizeWrappers } from "./1. Initialization";

export const responseManagerCallback = () => {
    // // console.log("called response manager")

    const { innerWidth, innerHeight } = window;

    // // console.log(`inner width: ${innerWidth}`);
    // // console.log(`inner height: ${innerHeight}`);

    resizeWrappers();
    repositionPhotos();
}

export const repositionPhotos = () => {
    const { innerWidth } = window;
    // @ts-ignore
    const picture: HTMLElement = document.getElementById('statement-picture');

    if (innerWidth < 750) {
        if (!window.currentlyPortrait) {
            // @ts-ignore
            // const insertionElement: HTMLElement = document.getElementById('statement-container');
            // insertionElement.insertAdjacentElement("afterend", picture);
            // const { bannerImgElement, bannerPortrait } = window.bannerData;
            // bannerImgElement.src = bannerPortrait;
            // window.currentlyPortrait = true;
        }
    } else {
        if (window.currentlyPortrait) {
            // @ts-ignore
            const insertionElement: HTMLElement = document.getElementById('mission-main-container');
            insertionElement.insertAdjacentElement('afterbegin', picture);
            const { bannerImgElement, bannerLandscape } = window.bannerData;
            bannerImgElement.src = bannerLandscape;
            window.currentlyPortrait = false;
        }
    }
}