import React, {useContext, useEffect} from 'react';
import NavContext from "../contexts/NavContext";
import navButton from "../photos/misc/nav-button.svg"

const NavButton = () => {

    let nav: any, setNav: any;
    [nav, setNav] = useContext(NavContext);

    const navButtonHandler = () => {
        setNav(!nav);
    }

    return (
        <div id="nav-button" onClick={() => navButtonHandler()} style={
            {
                left: `Calc(100vw - ${nav ? window.innerWidth < 750 ? 11 : 7 : window.innerWidth < 750 ? 4.5 : 7}em`,
                transform: `${nav ? "rotate(90deg)" : "unset"},`,
                top: `Calc(${window.innerHeight}px - ${window.innerWidth < 750 ? 4.5 : 7}em)`
            }}>
            <img src={navButton} />
        </div>
    )
}

export default NavButton;