import {functionalURL} from "../constants/1. Global";

export const pinPost = (data: string) => {
    return fetch(functionalURL, {
        method: 'get',
        headers: {
            "action": 'pin-post',
            "action-data": JSON.stringify({
                code: data
            })
        }
    }).then(r => {
        return r.json();
    }).then(json => {
        // console.log(json);
        return json.body.success;
    }).catch(err => {
        // console.log(err);
        return false;
    })
}