const Board = () => {
    return (
        <div id="board-container">
            <span  style={{marginBottom: "0"}} className="page-title">
                FWCAS Board of Directors
            </span>
            <div id="board-members">
                <div className="board-member">
                    <span className="full-name">Cindy Gordon</span>
                    <span className="position">President</span>
                    <a className="email" href="mailto: shorebird@tampabay.rr.com">shorebird@tampabay.rr.com</a>
                </div>
                <div className="board-member">
                    <span className="full-name">June Pruitt</span>
                    <span className="position">Vice President</span>
                    <a className="email" href="mailto: junebearfl@yahoo.com">junebearfl@yahoo.com</a>
                </div>
                <div className="board-member">
                    <span className="full-name">Gloria Schroeck</span>
                    <span className="position">Treasure</span>
                    <a className="email" href="mailto: gloriabirdea@msn.com">gloriabirdea@msn.com</a>
                </div>
                <div className="board-member">
                    <span className="full-name">Chris Reed</span>
                    <span className="position">Secretary</span>
                    <a className="email"  href="mailto: beautiful_birdies@comcast.net">beautiful_birdies@comcast.net</a>
                </div>
                <div className="board-member">
                    <span className="full-name">Karen Parker</span>
                    <span className="position">Member at Large</span>
                    <a className="email" href="mailto: dolphinwahini@aol.com">dolphinwahini@aol.com</a>
                </div>
            </div>
            <div id="misc-position">

            </div>
        </div>
    )
}

export default Board