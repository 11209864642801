import React, {useContext, useEffect, useRef, useState} from 'react';
import AuthContext from "../contexts/AuthContext";
import {pinPost} from "../api/pin-api";
import HeartAnimation from "./20. HeartAnimation";
import {analyzeLogin, checkToken} from "../api/auth-api";
import {getCookie, updateCookie} from '../utilities/3. Cookies'
import authBadgeLogo from '../photos/misc/unlocked.svg'

const AdminLogin = () => {

    let auth: any, setAuth: any;
    [auth, setAuth] = useContext(AuthContext);

    const [pin, setPin] = useState("");
    const [loading, setLoading] = useState(true);
    const initiated = useRef(false);

    useEffect(() => {
        if (initiated.current) setLoading(false)
    }, [auth, pin])

    useEffect(() => {
        checkToken()
            .then(r => {
                if (r['success']) {
                    let newAuth = {
                        authenticated: true,
                        pinFiltered: true,
                        username: r['verificationResults']['payload']['userEmail'],
                        name: r['verificationResults']['payload']['userName'],
                        token: getCookie("avian-auth"),
                    }
                    setAuth(newAuth);
                }
                setLoading(false);
                initiated.current = true;
            })
            .catch(err => {
                // console.log(err);
                initiated.current = true;
                setLoading(false);
            })
    }, [])

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const loginSubmitHandler = async (e: any) => {
        e.preventDefault()
        let valid = true;
        if (email.length > 100 || email.length < 10) valid = false;
        if (password.length === 0 || password.length > 1000) valid = false;

        // @ts-ignore
        if (valid) {
            let sanitized = btoa(encodeURI(email.toLowerCase() + password));
            setLoading(true);
            const result = await analyzeLogin(sanitized);
            if (result !== false) {
                if (result.success) {
                    updateCookie(result['response']['token']);
                    let newAuth = {
                        authenticated: true,
                        pinFiltered: true,
                        username: result['response'][result['response']['newToken'] ? "userEmail" : "email"],
                        name: result['response'][result['response']['newToken'] ? "userName" : "name"],
                        token: result['response']['token'],
                    }
                    setAuth(newAuth);
                }
                // authenticated, store token, update state, import logo,
            } else {
                alert("something went wrong");
            }
            setLoading(false);
        }
    }

    const handlePinPress = async (e: any) => {
        e.stopPropagation();

        let input = e.target.getAttribute('data-input');

        if (input === "C") {
            setPin("");
            return;
        }

        if (input === "E") {
            setLoading(true);
            if (await pinPost(pin)) {
                setAuth({...auth, pinFiltered: true});
            } else {
                setPin("");
                alert("invalid")
            }
            return
        }

        setPin(pin + input.toString());
    }

    return (
        <div id="login-main-container">
            {
                loading ? (
                        < HeartAnimation/>
                    ) :
                    auth.authenticated ? (
                        <div id="authorized-container">
                            <img id="auth-badge-logo" src={authBadgeLogo} width="100"/>
                            <span>
                                {`Welcome, ${auth.name}`}
                            </span>
                        </div>
                    ) : auth.pinFiltered ? (
                        <div id="login-screen">
                            <form>
                                <input type="email" placeholder="email" value={email}
                                       onChange={(e: any) => setEmail(e.target.value)}/>
                                <input type="password" placeholder="password" value={password}
                                       onChange={(e: any) => setPassword(e.target.value)}/>
                                <button type="submit" onClick={(e) => loginSubmitHandler(e)}>
                                    submit
                                </button>
                            </form>
                            {/*<GoogleLogin onSuccess={responseMessage} onError={() => errorMessage()} click_listener={() => // console.log("hi")} />*/}
                        </div>
                    ) : (
                        <div id="pin-pad-container">
                            {
                                [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "C", "E"].map((e, i) => {
                                    return (
                                        <div className="pin-pad-button" key={`PinPadButton-${i}`} data-input={e}
                                             onClick={(e) => handlePinPress(e)}>
                                            <span className="pin-pad-text" data-input={e}
                                                  onClick={(e) => handlePinPress(e)}>{e}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
            }
        </div>
    )
}

export default AdminLogin;