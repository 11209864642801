import {useEffect, useState} from "react";
import {initializeComponents, initializeReferences} from "../utilities/1. Initialization";
import {responseManagerCallback} from "../utilities/2. ResponseManager";
import {Footer} from "./4. Footer";
import {Header} from "./2.1 Header"
import {Home} from "./3. Home";
import {Navigation} from "./2. Navigation";
import { Outlet, useLocation } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";
// import {GoogleOAuthProvider} from '@react-oauth/google'
// import {OAuthClientId} from "../constants/1. Global";
import NavButton from "./9. NavButton";
import NavContext from "../contexts/NavContext";

// @ts-ignore
window.globalElements = {};
// @ts-ignore
window.bannerData = {};

export const App = () => {

    const [auth, setAuth] = useState({
        authenticated: false,
        pinFiltered: false,
        username: undefined,
        token: undefined,
    })



    const [nav, setNav] = useState(false);

    const { pathname } = useLocation();

    console.log(pathname);
    useEffect(() => {
        console.log("hey");
        // @ts-ignore
        document.getElementById('overflow-wrapper').scrollTo(0, 0)

        
        let header = document.getElementById('brand-header');
        if (pathname === "/home" || pathname === "/") {
            if (header) {
                header.style.display = "none";
            }
        } else {
            if (header) {
                header.style.display = "flex";
            }
        }
    }, [pathname])

    useEffect(() => {
        initializeReferences();
        initializeComponents();

        window.addEventListener('resize', responseManagerCallback);

        return () => window.removeEventListener('resize', responseManagerCallback);
    }, [])

    return (
        // <GoogleOAuthProvider clientId={OAuthClientId}>
            <AuthContext.Provider value={[auth, setAuth]}>
                <NavContext.Provider value={[nav, setNav]}>
                    <div id="global-wrapper">
                        <div id="overflow-wrapper">
                            <div id="shrink-wrapper">
                                <NavButton />
                                <Header />
                                <Outlet />
                                <Navigation />
                                <Footer />
                            </div>
                        </div>
                    </div>
                </NavContext.Provider>
            </AuthContext.Provider>
        // </GoogleOAuthProvider>
    )
}