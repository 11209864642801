export const navigationData = [
    {title: "HOME", subLabels: [], a: "/home"},
    {title: "NEWS & UPCOMING EVENTS", subLabels: ["FWCAS Events", "Lost and Found Birds", "Rescue Stories", "Bird of the Month"], a: "/events"},
    // {title: "ADMIN", subLabels: [], a: "/admin"},
    {title: "PARROT CARE", subLabels: ["PARROT 101", "LOCAL AREA VETS", "BIRD SITTING"]},
    {
        title: "FOSTER & ADOPTION",
        subLabels: ["FOSTER & ADOPTION PROGRAMS", "ADOPTABLE BIRDS"]
    },
    {title: "CONTACT INFORMATION & FORMS", subLabels: ["CONTACT US", "BOARD", "FORMS"], a: "/forms"},
]

export const exampleEvent = [
    {
        title: "Beach Get-Together",
        date: "1/1/2023",
        img: "A URL",
        data: "lorem ipsum blah blah text text text"
    }
]

export const functionalURL = "https://giu4x2naktrbilitrmsrfdni3i0apycf.lambda-url.us-east-1.on.aws/";

export const OAuthClientId = "280273659945-0abihang1k5go7vigtr5bd50ggtg5cmo.apps.googleusercontent.com";