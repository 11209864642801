import {repositionPhotos} from "./2. ResponseManager";

export const initializeReferences = () => {
    // // console.log("initializing references");
    // @ts-ignore
    window.globalElements.globalWrapper = document.getElementById('global-wrapper');
    // @ts-ignore
    window.globalElements.overflowWrapper = document.getElementById('overflow-wrapper');
    // @ts-ignore
    window.globalElements.shrinkWrapper = document.getElementById('shrink-wrapper');
    // @ts-ignore
    window.globalElements.footer = document.getElementById('footer');
    // @ts-ignore
    window.globalElements.footerNavLinkContainer = document.getElementById('footer-nav-link-container');
    // @ts-ignore
    window.globalElements.footerNavLinkContainers = document.getElementsByClassName('footer-nav-link-containers');
    // @ts-ignore
    window.globalElements.footerNavLinkTitles = document.getElementsByClassName('footer-nav-link-titles');
    // @ts-ignore
    window.globalElements.footerNavSubLinkContainers = document.getElementsByClassName('footer-nav-sub-link-containers');
    // @ts-ignore
    window.globalElements.footerNavSubLinks = document.getElementsByClassName('footer-nav-sub-links');
}
export const initializeComponents = () => {
    // // console.log("initializing components")
    resizeWrappers();
    repositionPhotos();
}

export const resizeWrappers = () => {
    const { innerHeight, innerWidth } = window;
    const { globalWrapper, overflowWrapper, shrinkWrapper } = window.globalElements;


    globalWrapper.style.minHeight = `${innerHeight}px`;
    globalWrapper.style.maxHeight = `${innerHeight}px`;

    overflowWrapper.style.minHeight = `${innerHeight}px`;
    overflowWrapper.style.maxHeight = `${innerHeight}px`;

    if (innerWidth < 1200) {
        shrinkWrapper.style.minWidth = "100%";
        shrinkWrapper.style.maxWidth = "100%";
    } else {
        shrinkWrapper.style.minWidth = "1200px";
        shrinkWrapper.style.maxWidth = "1200px";
    }
}