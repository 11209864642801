import React, {useEffect, useState} from 'react';
import adoptionApp from '../data/forms/adoption-app.pdf'
import adoptionCon from '../data/forms/adoption-contract.pdf'
import fosterApp from '../data/forms/foster-app.pdf'
import fosterCon from '../data/forms/foster-contract.pdf'
import surrenderForm from '../data/forms/surrender-form.pdf'
import membershipApp from '../data/forms/member-app.pdf'
import parrotSearch from '../data/forms/911 Parrot Search.pdf'
import photo0 from '../photos/forms photos/form0.jpg'
import photo1 from '../photos/forms photos/form1.jpg'
import dlLogo from '../photos/misc/download.svg'

const Forms = () => {
    let screenWidth: any, setScreenWidth: any
    [screenWidth, setScreenWidth] = useState(undefined);

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, [])

    if (screenWidth < 750) {
        return (
            <div id="form-container">
                <span className="page-title">Forms</span>
                <div id="form-photos-container">
                    <div className="forms-photo-container">
                        <img className="forms-photo" src={photo1}/>
                    </div>
                    <div className="forms-photo-container">
                        <img className="forms-photo" src={photo0}/>
                    </div>
                </div>
                <div id="forms">
                    <div className="form-group">
                        <h1>Membership Form</h1>
                            <a href={membershipApp} target="_blank"><span>Application</span> <img src={dlLogo} /></a>
                    </div>

                    <div className="form-group">
                        <h1>Surrender Form</h1>
                        <a href={surrenderForm} target="_blank"><span>Surrender Form</span> <img src={dlLogo} /></a>
                    </div>
                    <div className="form-group">
                            <h1>Adoption Forms</h1>
                        <a href={adoptionApp} target="_blank"><span>Application</span> <img src={dlLogo}/></a>
                        <div className="form-link-spacer"></div>
                        <a href={adoptionCon} target="_blank"><span>Contract</span> <img src={dlLogo} /></a>
                    </div>

                    <div className="form-group">
                    <h1>Foster Forms</h1>
                    <a href={fosterApp} target="_blank"><span>Application</span> <img src={dlLogo} /></a>
                    <div className="form-link-spacer"></div>
                    <a href={fosterCon} target="_blank"><span>Contract</span> <img src={dlLogo} /></a>

                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div id="form-container">
                    <div className="forms-photo-container">
                        <img className="forms-photo" src={photo1}/>
                    </div>
                <div id="forms">

            <span className="page-title">Forms</span>
                        <h1>Membership Form</h1>
                        <a href={membershipApp} target="_blank"><span>Application</span> <img src={dlLogo} /></a>

                        <h1>Adoption Forms</h1>
                        <a href={adoptionApp} target="_blank"><span>Application</span> <img src={dlLogo}/></a>
                        <div className="form-link-spacer"></div>
                        <a href={adoptionCon} target="_blank"><span>Contract</span> <img src={dlLogo} /></a>

                        <h1>Foster Forms</h1>
                        <a href={fosterApp} target="_blank"><span>Application</span> <img src={dlLogo} /></a>
                        <div className="form-link-spacer"></div>
                        <a href={fosterCon} target="_blank"><span>Contract</span> <img src={dlLogo} /></a>


                        <h1>Surrender Form</h1>
                        <a href={surrenderForm} target="_blank"><span>Surrender Form</span> <img src={dlLogo} /></a>

                </div>

                <div className="forms-photo-container">
                    <img className="forms-photo" src={photo0}/>
                </div>
            </div>
        )
    }


}

export default Forms;