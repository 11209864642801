import React, {useEffect, useState} from 'react';
import HeartAnimation from "./20. HeartAnimation";
import {useParams} from "react-router-dom";
import {getEvent} from "../api/events-api";
import arrow from '../photos/misc/arrow.svg';

const Event = (props: any) => {

    const params = useParams();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [event, setEvent] = useState<any>(undefined);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    useEffect(() => {
        getEvent(params['eventId'])
            .then(r => {
                setEvent(r['body']['response']['Items'][0]);
            })
            .catch(err => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [])

    useEffect(() => {
        if (event) {
            let el = document.getElementById('event-model-container')
            // @ts-ignore
            el.innerHTML = event.model.S
        }
    }, [event])

    const movePictureHandler = (dir: any) => {
        if (dir === "LEFT") {
            if (selectedImageIndex === 0) {
                // do nothing
            } else {
                setSelectedImageIndex(selectedImageIndex - 1);
            }
        } else if (dir === "RIGHT") {
            if (selectedImageIndex === event.imageURL.SS.length - 1) {
                // do nothing
            } else {
                setSelectedImageIndex(selectedImageIndex + 1)
            }
        }
    }

    const getDate = () => {
        let time = new Date(event.timestamp.S);
        return `${time.getMonth()}/${time.getDate()}/${time.getUTCFullYear()}`
    }

    const getTime = () => {
        let time: any = new Date(event.timestamp.S);
        // @ts-ignore
        time = `${time.getHours() > 12 ? `${time.getHours() - 12}` : `${time.getHours()}`}:${time.getMinutes().toString().padStart(2, "0")}`
        if (new Date(event.timestamp.S).getHours() > 11) {
            time += "p"
        } else {
            time += "a"
        }
        return time;
    }

    if (loading) {
        return (
            <div id="loading-container">
                <HeartAnimation />
            </div>
        )
    } else if (error) {
        return (
            <div id="loading-container">
                <span>ERROR</span>
            </div>
        )
    }
    else {
        return (
            <div id="open-event-page">
                    <span id="event-title">{event.name.S}</span>
                {
                    event.imageURL.SS[0] !== "NONE" ? (
                        <div id="event-image-container">
                            <div id="event-image-sub-container">
                                <img src={event.imageURL.SS[selectedImageIndex]} />
                            </div>

                            {
                                selectedImageIndex > 0 ? (
                                    <button type="button" id="img-left-button" className="img-button" onClick={() => movePictureHandler("LEFT")}>
                                        <img src={arrow} />
                                    </button>
                                ) : null
                            }
                            {
                                selectedImageIndex < event.imageURL.SS.length - 1 ? (
                                    <button disabled={selectedImageIndex === event.imageURL.SS.length - 1 }id="img-right-button" className="img-button" onClick={() => movePictureHandler("RIGHT")}>
                                        <img src={arrow} />
                                    </button>
                                ) : null
                            }
                        </div>
                    ) : null
                }
                <div id="event-meta-info-container">
                    <span>Location:<br></br> <b>{event.location.S}</b></span>
                    <span>Date: <br></br><b>{getDate().toString()}</b></span>
                    <span>Time:<br></br> <b>{getTime().toString()}</b></span>
                </div>
                <div id="event-model-main-container">
                    <span id="event-model-container">{event.model.S}</span>
                </div>
            </div>
        )
    }
}

export default Event;