import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import { App } from './components/1. App';
import './css/1. App.css'
import './css/2. Containers.css'
import './css/0. Index.css';
import './css/3. Navigation.css'
import './css/4. Home.css'
import './css/8. Footer.css';
import './css/6. Forms.css'
import './css/7. AdminLogin.css'
import './css/20. HeartAnimation.css'
import './css/9. Events.css'
import './css/12. Board.css'

import ErrorPage from "./components/10. Error";
import Forms from "./components/6. Forms";
import {Navigation} from "./components/2. Navigation";
import {Footer} from "./components/4. Footer";
import {Home} from "./components/3. Home";
import Events from "./components/7. Events";
import Event from './components/11. Event'
import AdminLogin from "./components/8. AdminLogin";
import  Board  from './components/12. Board';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/home",
                element: <Home />
            },            {
                path: "/events",
                element: <Events />,
            },
            {
                path: "/board",
                element: <Board />,
            },
            {
                path: "/forms",
                element: <Forms />
            },
            {
                path: "/admin",
                element: <AdminLogin />
            },
            {
                path: "/event/:eventId",
                element: <Event />,
            }
        ]
    }
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
<>
    {/*<Navigation />*/}
    <RouterProvider router={router} />
    {/*<Footer />*/}
</>
);
