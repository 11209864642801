import React, {useContext, useEffect, useRef, useState} from 'react';
import { useLocation } from 'react-router-dom';
import {checkToken} from "../api/auth-api";
import {getCookie} from "../utilities/3. Cookies";
import AuthContext from "../contexts/AuthContext";
import Event from '../building blocks/Event'
import {getEvents, putEvent, uploadPhotos} from "../api/events-api";
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import { useNavigate } from "react-router-dom";
import Buffer from 'buffer';
// @ts-ignore
import { uploadFile } from 'react-s3';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import AWS from 'aws-sdk';

import add from '../photos/misc/add.svg'
import HeartAnimation from "./20. HeartAnimation";
window.Buffer = window.Buffer || Buffer.Buffer
let date = new Date();
const Events = () => {
    const navigate = useNavigate();

    const [pageNum, setPageNum] = useState(0);
    const [events, setEvents] = useState<any>([]);
    const [newPage, setNewPage] = useState(false);
    const [currentEvent, setCurrentEvent] = useState<any>(false);
    const [editing, setEditing] = useState(false);
    const [urlsToDelete, setUrlsToDelete] = useState<any>([]);

    const [newDate, setNewDate] = useState<any>(`${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T${date.getHours().toString().padStart(2,'0')}:${date.getMinutes().toString().padStart(2, '0')}`);
    const [newTitle, setNewTitle] = useState("");
    const [newLocation, setNewLocation] = useState("");
    const [newFiles, setNewFiles] = useState("");
    const [newModel, setNewModel] = useState("");
    const [imgFiles, setImgFiles] = useState("");

    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);

    const imgUrls = useRef([]);

    const location = useLocation();

    useEffect(() => {
        if (editing === true && currentEvent !== false) {
            imgUrls.current = currentEvent.imageURL.SS;
            setNewDate(currentEvent.timestamp.S);
            setNewTitle(currentEvent.name.S);
            setNewLocation(currentEvent.location.S);
            setNewModel(currentEvent.model.S);
            setUrlsToDelete([]);
        }
    }, [editing, currentEvent])

    useEffect(() => {
        setLoading(true);
        refreshEvents().then(r => {
            setLoading(false);
        });
    }, [refresh])

    console.log(window.location.href);

    let auth: any, setAuth: any;
    [auth, setAuth] = useContext(AuthContext);
    const submitEventHandler = async (e: any) => {
        e.preventDefault(e);
        setLoading(true);
        let creds = await uploadPhotos();
        if (creds) {
            AWS.config.update({
                region: 'us-east-1',
                // @ts-ignore
                credentials: creds
            })

            const s3 = new AWS.S3({
                params: {
                    bucketName: "fwcas.data.bucket"
                }
            })

            let bucketId = editing ? currentEvent.id.S : Date.now() + Math.random().toString(36).substring(2, 9);

            console.log(imgFiles);
            if (editing) {
                if (urlsToDelete.length > 0) {
                    for (let i = 0; i < urlsToDelete.length; i++) {
                        console.log(urlsToDelete[i])
                        await s3.deleteObject({
                            Bucket: 'fwcas.data.bucket',
                            Key: urlsToDelete[i].substring(urlsToDelete[i].indexOf("fwcas.data.bucket/") + 18),
                        }).promise()
                        .then(data => {
                            // @ts-ignore
                            imgUrls.current.splice(imgUrls.current.indexOf(urlsToDelete[i]), 1)
                        })
                        .catch(err => {
                            console.log("error deleting photo")
                            console.error(err)
                        })
                    }
                }
            }
            for (let i = 0; i < imgFiles.length; i++) {
                await s3.upload({
                    Bucket: 'fwcas.data.bucket',
                    Key: `${bucketId}/${Date.now() + Math.random().toString(36).substring(2, 9)}`,
                    Body: imgFiles[i],
                    // @ts-ignore
                    ContentType: `${imgFiles[i]['type']}`,
                    ACL: 'public-read'
                }).promise()
                    .then(data => {
                        // // console.log(data);
                        // @ts-ignore
                        imgUrls.current.push(data['Location']);
                    })
                    .catch(err => {
                        console.error(err);
                    })
            }

            let obj = {
                id: bucketId,
                name: newTitle,
                timestamp: newDate,
                model: newModel,
                location: newLocation,
                images: imgUrls.current.length === 0 ? ["NONE"] : imgUrls.current,
            }

            await putEvent(obj);
            setLoading(false)
            setNewPage(false);

            setNewTitle("");
            setNewDate("");
            setNewModel("");
            setNewLocation("");
            imgUrls.current = [];
            setNewFiles("");
            refreshEvents().then(r => r);

        }
        setLoading(false);
    }

    const refreshEvents = async () => {
        // @ts-ignore
        let events = await getEvents(pageNum);
        console.log(events);
        let sortedEvents = events['body']['response']['Items'].sort((a: any, b: any) => a.timestamp.S < b.timestamp.S ? 1 : a.timestamp.S > b.timestamp.S ? -1 : 0)
        if (events) {
            setEvents(sortedEvents);
        }
    }

    useEffect(() => {
        checkToken()
            .then(r => {
                if (r['success']) {
                    let newAuth = {
                        authenticated: true,
                        pinFiltered: true,
                        username: r['verificationResults']['payload']['userEmail'],
                        name: r['verificationResults']['payload']['userName'],
                        token: getCookie("avian-auth"),
                    }
                    setAuth(newAuth);
                }
            })
            .catch(err => {
                console.error(err);
            })
    }, [])

    const handleImgDelete = (urlInfo: any) => {
        setUrlsToDelete([...urlsToDelete, urlInfo])
        // @ts-ignore
        document.getElementById(`imgUrl-${urlInfo}`).style.display = "none";
        console.log(urlsToDelete)
    }

    const cancelButtonHandler = () => {
        setEditing(false);
        setCurrentEvent(false);
        setNewPage(false);
        setLoading(false)
        setNewPage(false);
        setNewTitle("");
        setNewDate("");
        setNewModel("");
        setNewLocation("");
        imgUrls.current = [];
        setNewFiles("");
        refreshEvents().then(r => r);
    }


    return (
        <div id="events-container">
            <span  style={{marginBottom: "0"}} className="page-title">
                Upcoming Events
            </span>
            {
                loading ? (
                        <div id="animation-container">
                            <HeartAnimation />
                        </div> 
                        ):
                newPage ? (
                    <div id="new-page-container">
                            <form>
                                <div id="attributes-container">
                                    <input type="datetime-local" value={newDate} onChange={(e: any) => setNewDate(e.target.value)}/>
                                    <input type="text" placeholder="event title" value={newTitle} onChange={(e: any) => setNewTitle(e.target.value)}/>
                                    <input type="text" placeholder="event location" value={newLocation} onChange={(e: any) => setNewLocation(e.target.value)}/>
                                    <div id="file-manager-container">
                                        <input id="file-input" type="file" multiple={true} accept="image/*" value={newFiles} onChange={(e: any) => {
                                            setImgFiles(e.target.files);
                                            setNewFiles(e.target.value)
                                        }}/>
                                        {
                                            editing ? (
                                                <div id="event-photo-urls-container">
                                                    {
                                                        currentEvent.imageURL.SS.map((e: any, i: any) => {
                                                            if (e === "NONE") {
                                                                return;
                                                            }
                                                            return (
                                                                <div className="img-url-container" id={`imgUrl-${e}`}>
                                                                    <span>Image {`${i + 1}`}</span>
                                                                    <button type="button" onClick={(a) => handleImgDelete(e)}>
                                                                        DELETE
                                                                    </button>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                </div>
                                <div id="editor-container">
                                    <FroalaEditorComponent
                                        tag="textarea"
                                        config={{
                                            heightMin: '500px',
                                            heightMax: '500px',
                                        }}
                                        model={newModel}
                                        onModelChange={(e: any) => setNewModel(e)}
                                    />
                                </div>
                                <button id="event-submit-button" type="button" onClick={() => cancelButtonHandler()}>Cancel</button>
                                <button id="event-submit-button" type="submit" onClick={(e: any) => submitEventHandler(e)} onSubmit={(e: any) => e.preventDefault()}>{ editing ? "Update" : "Submit"}</button>
                            </form>
                        {
                            loading ? (
                                <div id="loader">
                                    <HeartAnimation />
                                </div>
                            ) : null
                        }
                    </div>
                ) : auth.authenticated ? (
                    <>
                        <img src={add}  alt="" onClick={(e) => setNewPage(true)}/>
                    </>
                ) : null
            }
            {
                loading ? (
                        null
                    ) :
                newPage ?
                    null : (
                    <>
                        {
                            events.map( (e: any, i: any) => {
                                return (
                                    <Event 
                                    data={e} 
                                    key={`event-${e.id.S}`} 
                                    refresh={() => setRefresh(!refresh)} 
                                    setLoading={setLoading} 
                                    setEditing={setEditing} 
                                    setCurrentEvent={setCurrentEvent} 
                                    setNewPage={setNewPage} />
                                )
                            })
                        }
                    </>
                )
            }
        </div>
    )
}

export default Events;