import {getCookie, updateCookie} from "../utilities/3. Cookies";

const getEvents = async (pageNum: any) => {
    return await fetch("https://giu4x2naktrbilitrmsrfdni3i0apycf.lambda-url.us-east-1.on.aws/", {
        method: "get",
        headers: {
            'action': 'get-events',
            'action-data': JSON.stringify({
                pageNum: pageNum,
            }),
            'cache-control': "no-store",
        }
    }).then(r => {
        return r.json();
    }).then(json => {
        // // console.log(json)
        // console.log("YOOOO")
        return json;
    }).catch( err => {
        // console.log(err);
        return false;
    })
}

const deleteEvent = async (eventData: any) => {
    let token = getCookie('avian-auth');
    return await fetch("https://giu4x2naktrbilitrmsrfdni3i0apycf.lambda-url.us-east-1.on.aws/", {
        method: "delete",
        headers: {
            'action': 'delete-event',
            "authorization": `Bearer ${token}`,
            'action-data': JSON.stringify({
                id: eventData.id.S,
                timestamp: eventData.timestamp.S
            }),
            'cache-control': "no-store",
        }
    }).then(r => {
        return r.json();
    }).then(json => {
        console.log(json);
        // // console.log(json)
        // console.log("YOOOO")
        return json;
    }).catch( err => {
        console.log(err);
        // console.log(err);
        return false;
    })
}

const getEvent = async (eventId: any) => {
    return await fetch("https://giu4x2naktrbilitrmsrfdni3i0apycf.lambda-url.us-east-1.on.aws/", {
        method: "get",
        headers: {
            'action': 'get-event',
            'action-data': JSON.stringify({
                eventId: eventId,
            })
        }
    }).then(r => {
        return r.json();
    }).then(json => {
        // // console.log(json)
        return json;
    }).catch( err => {
        // console.log(err);
        return false;
    })
}

const putEvent = async (data: any) => {
    let token = getCookie('avian-auth');
    return await fetch("https://giu4x2naktrbilitrmsrfdni3i0apycf.lambda-url.us-east-1.on.aws/", {
        method: "get",
        headers: {
            "authorization": `Bearer ${token}`,
            'action': 'put-event',
            'action-data': JSON.stringify({
                event: data
            }),
        }
    }).then(r => {
        // // console.log(r);
        return r.json();
    }).then(json => {
        // // console.log(json)
        return json;
    }).catch( err => {
        // console.log(err);
        return false;
    })
}

const uploadPhotos = async () => {
    let token = getCookie('avian-auth');
    return await fetch("https://giu4x2naktrbilitrmsrfdni3i0apycf.lambda-url.us-east-1.on.aws/", {
        method: "get",
        mode: "cors",
        headers: {
            "authorization": `Bearer ${token}`,
            'action': 's3-photo-upload',
        }
    }).then(r => {
        return r.json();
    }).then(json => {
        updateCookie(json.body['newToken']);
        return {
            accessKeyId: json.body['response']['d'],
            secretAccessKey: json.body['response']['e'],
        };
    }).catch( err => {
        // console.log(err);
        return false;
    })
}

export {
    getEvents,
    uploadPhotos,
    putEvent,
    getEvent,
    deleteEvent
}