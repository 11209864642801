import picture1 from '../photos/birds/bird-pic.jpg';
import picture2 from '../photos/birds/african-greys.jpg'
import picture3 from '../photos/birds/hyacinth.jpeg';
import picture4 from '../photos/birds/outro-2.jpeg';
import picture5 from '../photos/birds/outro-3.jpeg';
import banner from '../photos/misc/banner.jpg'
import bannerPortrait from '../photos/misc/banner-portrait.jpg';
import facebookLogo from '../photos/misc/facebook-logo.jpg';
import amazonSmileLogo from '../photos/misc/amazon-smile-logo.png';
import givingPartnerLogo from '../photos/misc/giving-partner-logo.png'
import paypalDonateLogo from '../photos/misc/paypal-donate-logo.png';
import mission0 from '../photos/mission statement photos/image-000.jpg'
import mission1 from '../photos/mission statement photos/image-001.jpg'
import mission2 from '../photos/mission statement photos/image-002.jpg'
import mission3 from '../photos/mission statement photos/image-003.jpg'
import mission4 from '../photos/mission statement photos/image-004.jpg'
import mission5 from '../photos/mission statement photos/image-005.jpg'
import mission6 from '../photos/mission statement photos/image-006.jpg'
import mission7 from '../photos/mission statement photos/image-007.jpg'
import React, {useEffect} from "react";
import { useLocation } from 'react-router';

export const Home = () => {

    useEffect(() => {
        const s = document.createElement("script");
        s.setAttribute('src', 'https://www.paypalobjects.com/donate/sdk/donate-sdk.js');
        s.onload = () => {
            // @ts-ignore
            PayPal.Donation.Button({
                env:'production',
                hosted_button_id:'7956NBZZCT9ZY',
                image: {
                    src: paypalDonateLogo,
                    alt:'Donate with PayPal button',
                    title:'PayPal - The safer, easier way to pay online!',
                }
            }).render('#donate-button');
        }
        document.head.insertBefore(s, document.head.firstElementChild);
        // console.log(s);
    }, [])

    useEffect(() => {
        window.bannerData.bannerPortrait = bannerPortrait;
        window.bannerData.bannerLandscape = banner;
        window.bannerData.bannerImgElement = document.getElementById('banner-img');
    }, [])





    // @ts-ignore
    return (
        <div id="home-container">
            <div id="banner-container">
                <img src={window.innerWidth < 750 ? bannerPortrait : banner} alt="fwcas banner" id="banner-img" />
            </div>
            <div id="mission-main-container">

                <div style={{margin: "1em 0"}}>
                <h1 id="mission-title" style={{marginTop: "0"}}>
                    {window.innerWidth < 750 ? "FWCAS" : "The Florida West Coast Avian Society"}
                </h1>
                <h1 id="mission-title">
                    Mission Statement
                </h1>
                </div>
                <div id="mission-title-picture-container">
                    <img src={mission0} className="mission-title-picture"/>
                    <img src={mission1} className="mission-title-picture"/>
                </div>
                <div id="mission-statements-container">
                    <span className="statement-regular" style={{minWidth: '100%'}}>
                        <h1 className="statement-first-word">To&nbsp;</h1>
                        rescue, rehabilitate and re-home unwanted, abused and/or abandoned parrots by providing emergency medical care or placement through fostering and adoption protocols.
                    </span>
                    <span className="statement-regular" style={{minWidth: '100%'}}>
                        <h1 className="statement-first-word" >The&nbsp;</h1>
                        FWCAS is the leading multi-county organization providing and promoting education to current and potential parrot owners and the community at large regarding parrot welfare both in normal and crisis situations.
                    </span>
                </div>
                <div id="mission-title-picture-container">
                    <img src={mission2} className="mission-title-picture" />
                    <img src={mission4} className="mission-title-picture" />
                </div>

                <div style={{margin: "1em 0 .5em 0"}}>
                    <h1 id="mission-title">
                        What We Have
                    </h1>
                    <h1 id="mission-title">
                       Done For Decades
                    </h1>
                </div>

                <div className="statement-even">
                    <div className="statement-photo-container">
                    <img src={mission3} className="statement-picture" />
                    </div>
                    <span className="statement-regular padding-left">
                        <h1 className="statement-first-word">Assist&nbsp;</h1>
                        area organizations that rescue, rehabilitate, temporarily house and/or re-home pet exotic birds and parrots and/or wild parrots that have been abandoned, lost, injured or are at risk of neglect or mistreatment.
                    </span>
                </div>
                <div className="form-link-spacer"></div>
                <div className="statement-odd">
                <span className="statement-regular padding-right">
                    <h1 className="statement-first-word">Educate&nbsp;</h1>
                    the public and the membership to ensure the proper care, treatment, and preservation of exotic pet birds, and, to ensure the preservation of exotic birds in the wild.
                </span>
                    <div className="statement-photo-container">
                <img src={mission5} className="statement-picture" />
                    </div>
                </div>
                <div className="form-link-spacer"></div>
                    <div className="statement-even">
                        <div className="statement-photo-container">
                        <img src={mission6} className="statement-picture" />
                        </div>
                        <span className="statement-regular padding-left">
                            <h1 className="statement-first-word">Keep&nbsp;</h1>
                            informed of Federal, State, and Local laws and regulations pertaining to the keeping of exotic birds.
                        </span>
                    </div>

                <div className="form-link-spacer"></div>
                <div className="statement-odd">
                    <span className="statement-regular padding-right">
                        <h1 className="statement-first-word">Exchange&nbsp;</h1>
                        knowledge, information, and experiences regarding care, diet, disease, and behavior relative to exotic birds.
                    </span>
                    <div className="statement-photo-container">
                        <img src={mission7} className="statement-picture" />
                    </div>
                </div>
            </div>
            <div id="links-main-container">
                <img id="african-greys-perched" src={picture2} alt="african greys perched" width="500"/>
                <div
                    className="link-structure"
                    id="link-structure-top">
                    <div className="link-containers">
                        <a href="https://www.facebook.com/groups/778567642685656" target="_blank">
                            <img src={facebookLogo} alt="facebook logo and link"/>
                        </a>
                    </div>
                    <div className="link-containers">
                        <div id="donate-container">
                            <div id="donate-button">
                                {/*<img src={paypalDonateLogo} alt="Donate link via paypal"/>*/}
                            </div>
                        </div>
                    </div>
                    <div
                        className="link-containers">
                        <a href="https://www.thegivingpartner.org/organizations/florida-west-coast-avian-society-inc" target="_blank">
                            <img style={{backgroundColor: 'white', padding: '.1em', boxSizing: 'border-box'}}
                                 src={givingPartnerLogo}
                                 alt="the giving partner logo and link"/>
                        </a>
                    </div>
                </div>
                {/*<div className="link-structure"*/}
                {/*     id="link-structure-bottom">*/}

                    {/*<div className="link-containers">*/}
                    {/*    <h2>*/}
                    {/*        Support us via Amazon Smile*/}
                    {/*    </h2>*/}
                    {/*    <img src={amazonSmileLogo} alt="amazon smile logo and link"/>*/}
                    {/*</div>*/}
                {/*</div>*/}
            </div>
            {/*<hr style={{marginBottom: '16px'}} className="section-separator"/>*/}
            {/*<div id="outro-container">*/}
            {/*    <div className="photo-container">*/}
            {/*        <img className="outro-photos" src={picture3} alt="hyacinth macaw"/>*/}

            {/*    </div>*/}
            {/*    <div className="photo-container">*/}
            {/*        <img className="outro-photos" src={picture4} alt="hyacinth macaw"/>*/}

            {/*    </div>*/}
            {/*    <div className="photo-container">*/}
            {/*        <img className="outro-photos" src={picture5} alt="hyacinth macaw"/>*/}

            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}